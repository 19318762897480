import React, { useState } from "react";
import { ConfigProvider, Layout, Menu } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  HomeOutlined,
  SettingOutlined,
  LogoutOutlined,
  FireOutlined,
  FileSearchOutlined,
  ExceptionOutlined,
  FileAddOutlined,
  ScheduleOutlined,
  PrinterOutlined,
  CaretRightOutlined,
  AuditOutlined,
} from "@ant-design/icons";
import {
  Link,
  Outlet,
  // useLocation,
  useNavigate,
} from "react-router-dom";

// import logo from "../assests/images/logo.png";

const { Header, Sider, Content } = Layout;

function MainLayout() {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  // const location = useLocation();

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const logoutClicked = () => {
    //TODO: api call for token cancellation
    let user = JSON.parse(localStorage.getItem("user"));
    localStorage.removeItem("authToken");
    localStorage.removeItem("user");
    localStorage.removeItem("clientConfig");
    localStorage.clear();
    if (user.role === "admin") {
      navigate("/admin/login");
    } else {
      navigate("/mis/login");
    }
  };

  const isAllowed = (allowedRoles) => {
    let user = JSON.parse(localStorage.getItem("user"));
    let userRole = user ? user.role : null;
    let isAllowed = false;
    if (userRole) {
      isAllowed = allowedRoles.find((role) => role === userRole);
    }
    return isAllowed;
  };

  const items = [
    {
      label: "Dashboard",
      path:
        JSON.parse(localStorage.getItem("user")).role === "admin"
          ? "admin/dashboard"
          : "mis/dashboard",
      key: "dashboard",
      icon: <HomeOutlined />,
    },
    {
      label: "Site Visit Forms",
      path:
        JSON.parse(localStorage.getItem("user")).role === "admin"
          ? "admin/site/visit-forms"
          : "mis/site/visit-forms",
      key: "visit-forms",
      icon: <FileSearchOutlined />,
    },
    {
      label: "QRT Forms",
      path:
        JSON.parse(localStorage.getItem("user")).role === "admin"
          ? "admin/site/qrt-forms"
          : "mis/site/qrt-forms",
      key: "qrt-forms",
      icon: <ExceptionOutlined />,
    },
    {
      label: "Site Takeover Forms",
      path:
        JSON.parse(localStorage.getItem("user")).role === "admin"
          ? "admin/site/takeover-forms"
          : "mis/site/takeover-forms",
      key: "takeover-forms",
      icon: <FileAddOutlined />,
    },
    {
      label: "Material Requirement",
      path: "admin/material-requirement",
      key: "material-requirement",
      icon: <ScheduleOutlined />,
      permission: ["admin"],
    },
    {
      label: "Shutter Activity",
      path:
        JSON.parse(localStorage.getItem("user")).role === "admin"
          ? "admin/shutter-activity"
          : "mis/shutter-activity",
      key: "shutter-activity",
      icon: <ScheduleOutlined />,
    },
    {
      label: "Theft/Fire",
      path:
        JSON.parse(localStorage.getItem("user")).role === "admin"
          ? "admin/fire-incidents"
          : "mis/fire-incidents",
      key: "fire-incidents",
      icon: <FireOutlined />,
    },
    {
      label: "Supervisor Visit Form",
      path:
        JSON.parse(localStorage.getItem("user")).role === "admin"
          ? "admin/supervisor-visit-forms"
          : "mis/supervisor-visit-forms",
      key: "supervisor-visit-forms",
      icon: <AuditOutlined />,
    },
    {
      label: "Settings",
      key: "Settings",
      icon: <SettingOutlined />,
      permission: ["admin"],
      children: [
        {
          label: "User",
          path: "admin/user",
          key: "user",
          icon: <CaretRightOutlined />,
          permission: ["admin"],
        },
        {
          label: "Customer",
          path: "admin/customer",
          key: "customer",
          icon: <CaretRightOutlined />,
          permission: ["admin"],
        },
        {
          label: "Vendor",
          path: "admin/vendor",
          key: "vendor",
          icon: <CaretRightOutlined />,
          permission: ["admin"],
        },
        {
          label: "Supervisor RA relation",
          path: "admin/supervisor-ra",
          key: "supervisor-ra",
          icon: <CaretRightOutlined />,
          permission: ["admin"],
        },
        {
          label: "Mis Supervisor relation",
          path: "admin/mis-supervisor",
          key: "mis-supervisor",
          icon: <CaretRightOutlined />,
          permission: ["admin"],
        },
        {
          label: "Sites",
          path: "admin/sites",
          key: "sites",
          icon: <CaretRightOutlined />,
          permission: ["admin"],
        },
        {
          label: "RA Sites relation",
          path: "admin/ra-sites",
          key: "ra-sites",
          icon: <CaretRightOutlined />,
          permission: ["admin"],
        },
      ],
    },
    {
      label: "Reports",
      key: "Reports",
      icon: <PrinterOutlined />,
      permission: ["admin"],
      children: [
        {
          label: "Rewards Statement",
          path: "admin/reports/rewards-statement",
          key: "rewards-statement",
          icon: <CaretRightOutlined />,
          permission: ["admin"],
        },
        {
          label: "Summary Report",
          path: "admin/reports/summary-report",
          key: "summary-report",
          icon: <CaretRightOutlined />,
          permission: ["admin"],
        },
        {
          label: "Raw Report",
          path: "admin/reports/main-report",
          key: "main-report",
          icon: <CaretRightOutlined />,
          permission: ["admin"],
        },
        {
          label: "Raw Report With Images",
          path: "admin/reports/main-report-date-range",
          key: "main-report-date-range",
          icon: <CaretRightOutlined />,
          permission: ["admin"],
        },
        {
          label: "Material Requirement Report",
          path: "admin/reports/material-requirements",
          key: "material-requirements",
          icon: <CaretRightOutlined />,
          permission: ["admin"],
        },
        {
          label: "Supervisor Visit Form",
          path: "admin/reports/supervisor-visit-form",
          key: "supervisor-visit-form",
          icon: <CaretRightOutlined />,
          permission: ["admin"],
        },
        {
          label: "AC Non Functional",
          path: "admin/reports/ac-non-functional",
          key: "ac-non-functional",
          icon: <CaretRightOutlined />,
          permission: ["admin"],
        },
        {
          label: "Signage Non Functional",
          path: "admin/reports/signage-non-functional",
          key: "signage-non-functional",
          icon: <CaretRightOutlined />,
          permission: ["admin"],
        },
        {
          label: "Lollipop Non Functional",
          path: "admin/reports/lollipop-non-functional",
          key: "lollipop-non-functional",
          icon: <CaretRightOutlined />,
          permission: ["admin"],
        },
        {
          label: "Theft / Fire Report",
          path: "admin/reports/theft-fire",
          key: "theft-fire",
          icon: <CaretRightOutlined />,
          permission: ["admin"],
        },
        {
          label: "Shutter Activity Report",
          path: "admin/reports/shutter-activity-report",
          key: "shutter-activity-report",
          icon: <CaretRightOutlined />,
          permission: ["admin"],
        },
        {
          label: "Missed Visits",
          path: "admin/reports/missed-visits",
          key: "missed-visits",
          icon: <CaretRightOutlined />,
          permission: ["admin"],
        },
        {
          label: "Site Activation Report",
          path: "admin/reports/site-activate-deactivate",
          key: "site-activate-deactivate",
          icon: <CaretRightOutlined />,
          permission: ["admin"],
        },
        {
          label: "User Activation Report",
          path: "admin/reports/user-activate-deactivate",
          key: "user-activate-deactivate",
          icon: <CaretRightOutlined />,
          permission: ["admin"],
        },
      ],
    },
    {
      label: "Log Out",
      key: "logout",
      icon: <LogoutOutlined />,
      onClick: logoutClicked,
    },
  ];

  let href = window.location.href?.split("/");
  href = href.slice(-1);

  return (
    <Layout>
      <Sider trigger={null} collapsible collapsed={collapsed} theme="light">
        {/* <div className="logo">
          <img style={{ height: 50, width: "100%" }} src={logo} />
        </div> */}
        <ConfigProvider
          theme={{
            token: {
              fontSize: 14,
              itemBorderRadius: 16,
            },
          }}
        >
          <Menu
            theme="light"
            mode="inline"
            defaultSelectedKeys={href}
            selectedKeys={href}
            items={items.map((l1_item, index) => {
              let return_status = 0;
              if (l1_item?.permission) {
                if (isAllowed(l1_item?.permission)) {
                  return_status = 1;
                }
              } else {
                return_status = 1;
              }
              return (
                return_status && {
                  ...l1_item,
                  label: l1_item?.path ? (
                    <Link to={l1_item?.path}>{l1_item?.label}</Link>
                  ) : (
                    <>{l1_item?.label}</>
                  ),
                  children: l1_item?.children?.map((l2_item, l2_index) => {
                    let return_status = 0;
                    if (l2_item?.permission) {
                      if (isAllowed(l2_item?.permission)) {
                        return_status = 1;
                      }
                    } else {
                      return_status = 1;
                    }
                    return (
                      return_status && {
                        ...l2_item,
                        label: <Link to={l2_item?.path}>{l2_item?.label}</Link>,
                      }
                    );
                  }),
                }
              );
            })}
          ></Menu>
        </ConfigProvider>
      </Sider>
      <Layout
        className="site-layout"
        style={{
          minHeight: "100vh",
          width: "100%",
        }}
      >
        <Header style={{ padding: 0 }}>
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger",
              onClick: toggle,
            }
          )}
        </Header>
        <Content
          style={{
            margin: "24px 16px",
            padding: 24,
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
}

export default MainLayout;
