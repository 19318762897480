export const API_METHODS = {
  GET: "get",
  POST: "post",
  PUT: "put",
  DELETE: "delete",
};

export const DEFAULT_DATE_FORMAT = "DD-MM-YYYY";
export const BLANK_VALUE = "__";
export const EMPTY_VALUE = "";
// export const PORTAL_URL = "http://localhost:3000";
export const PORTAL_URL = "https://pflege.webthoughts.in";
